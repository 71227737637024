import React from "react"
import Layout from "../components/layout"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import TextBlockImg from "../components/TextBlockImg/textBlockImg"
import Perk from "../components/Perk/perk"
import Button from "../components/Button/button"
import Packages from "../components/Packages/packages"
import Package from "../components/Package/package"
import Contact from "../components/Contact/contact"
import { Link } from "react-scroll"

//import perk1Img from "../images/speed.svg"
//import perk2Img from "../images/piggy-bank.svg"
import perk3Img from "../images/friendly-staff.svg"

import { IconContext } from "react-icons"
import { MdDone, MdClear } from "react-icons/md"

export default () => (
  <>
    <Layout>
      <Banner />
      <TextBlock
        id="about"
        title="IT ei välttämättä ole aina ihan helppoa mutta onneksi sitä varten on LevelSafety"
        paragraph="Olen työskennellyt IT alalla eri tehtivissä vuosia. Järjestelmäasiantuntijana, Sovelluskehittäjänä, DevOps kehittäjänä, Pilvipalveluiden parissa jne."
      >
        <Link to="perks" smooth={true} duration={500}>
          <Button label="Tell Me More" cta="Kerro lisää!" />
        </Link>
      </TextBlock>
      <TextBlockImg
        id="perks"
        title="Kokemusta tekemiseen"
        subtitle="Minulla on yli 15 vuoden kokemus IT alan eri kulmilta ja aina olen innokas opettelemaan jotain uutta. Olen toiminut mm. Team Lead, Senior SRE, Senior Developer, Fullstack Developer, DevOps rooleissa joten osaan katsoa asioita useammalta kantilta myös bisnes noin yleensä kiinnostaa joten yritän siltäkin kannalta aina miettiä ratkaisut. 
        Tässä jotain esimerkkejä joiden ympärillä pystyn auttamaan."
      >
        <div className="content-container">
          <Perk
            img={perk3Img}
            alt="Pilvipalvelut"
            title="Cloud"
            content="Nykypäivänä harvoin kannattaa itse hankkia omat palvelimet, 
            monesti ei välttämättä kannata hankkia edes virtuaali palvelimia koska nykypäivän pilviteknologiat tarjoavat kustannustehokkaita ja helposti ylläpidettäviä ratkaisuja. 
            Esimerksiksi AWS, Azure tai Google Cloud.
            Jos olet miettinyt esimerkiksi kustannuksien optimointia tai pilven reunalle siirtymistä mutta osaaminen puuttuu niin otahan yhteyttä niin tehdään hallittava kokonaisuus käyttäen viimeisimpiä
            työkaluja ja tekniikoita."
          />
          <Perk
            img={perk3Img}
            alt="DevOps"
            title="Kehitysprosesseihin DevOpsia?"
            content="Development and Operations, Dev & Ops, rakkaalla lapsella on monta nimeä. DevOps ei ole pelkkiä työkaluja vaan myös kulttuuri miten asioita tehdään.
            Onko sovelluspäivitykset asiakkaille aina käsityötä? 
            Ajetaanko automaatiotestit ainoastaan käsin? 
            Tarvitseeko CI/CD putkisto kehittämistä? 
            Onko palvelinympäristö versiohallinnassa? Pitäisikö kehittäjienkin pystyä päivittämään palvelimille sovelluksiaan? Jos vastasit johonkin kyllä niin otahan yhteyttä."
          />
          <Perk
            img={perk3Img}
            alt="Homepages"
            title="Kotisivut"
            content="Moni yritys hyötyisi nykyaikaista kotisivuista. Niiden avulla voidaan parantaa mm. saavutettavuutta, myyntiä, näkyvyyttä, asiakaskokemusta jne.
            Monesti vaikka on kotisivut niin niiden ylläpito maksaa maltaita tai niiden päivittäminen on kallista/hidasta. 
            Ota yhteyttä jos haluat että tehdään kotisivusi nykypäivän tekniikoilla ja että ne toimivat NOPEASTI. 
            Esimerkiksi hakukoneet nykyään arvostavat sisällön lisäksi sitä että sivut latautuvat nopeasti ja ovat käytettäviä myös mobiililla. Edelleen näkeen liian monia sivuja jotka toimii luokattoman
            huonosti mobiili laitteilla ja näin ollen myyntiä menee sivusuun. Nykypäivän n. 80% surffauksesta tehdään jollakin muulla laitteella kuin tietokoneella joten mobiiliti toimivuus on pakollista jos
            halutaan että internet näkyvyys on kohdallaan."
          />
          <Perk
            img={perk3Img}
            alt="Design"
            title="Sovellus/Järjestelmä arkkitehtuuri"
            content="Onko mielessä hyvä idea mutta vielä hieman hakusessa miten ja millä teknologioilla lähtisi sitä toteuttamaan? TAI nykyinen
            tuote kaipaa pientä päivitystä koska se ei enää skaalaudu nykyisiin asiakamäärin tai on raskas ylläpitää."
          />
        </div>
      </TextBlockImg>
      <Packages
        title="Mitä se sitten maksaa?"
        para="Arvostan läpinäkyvyyttä joten tässäpä tavoite tuntihintani. Kaikesta voi toki aina neuvotella."
      >
        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          <Package title="Web3 Project">
            Koska Web3/Blockchain projekti kokemukseni vielä vähäistä, voin lähteä projektiin pienemmällä hinnalla.
            <ul>
            <li>
                <MdDone />
                Aloitus palaveri ilmaiseksi (1h)
              </li>
              <li>
                <MdDone />
                Senior/Lead tason osaaminen yleisesti ohjelmisto kehityksestä ja DevOpsista
              </li>
              <li>
                <MdDone />
                Joustava palvelu
              </li>
              <li>
                <MdDone />
                74e/h + alv
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="Contact" cta="Ota yhteyttä!" />
            </Link>
          </Package>
          <Package title="Projekti Tuntihinnalla" active={true}>
            Senior/Lead tason kehittäjä. 
            <ul>
            <li>
                <MdDone />
                Aloitus palaveri ilmaiseksi (1h)
              </li>
              <li>
                <MdDone />
                Senior/Lead tason osaaminen yleisesti ohjelmisto kehityksestä ja DevOpsista
              </li>
              <li>
                <MdDone />
                Joustava palvelu
              </li>
              <li>
                <MdDone />
                80e/h + alv
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="Contact" cta="Ota yhteyttä!" />
            </Link>
          </Package>
          <Package title="Kiinteä KK-hinta" active={false}>
            Senior/Lead tason kehittäjä kuukausihinnalla.
            <ul>
            <li>
                <MdDone />
                Aloitus palaveri ilmaiseksi (1h)
              </li>
              <li>
                <MdDone />
                Senior/Lead tason osaaminen yleisesti ohjelmisto kehityksestä ja DevOpsista
              </li>
              <li>
                <MdDone />
                Joustava palvelu
              </li>
              <li>
                <MdDone />
                12 400e/kk + Alv
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="Contact" cta="Ota yhteyttä!" />
            </Link>
          </Package>

        </IconContext.Provider>
      </Packages>

      <Contact
        id="contact"
        title="Ota yhteyttä"
        subtitle="Lähetä sähköpostia mikko(at)levelsafety.fi tai soita p. +358503740004 niin palaan asiaan mahdollisiman pian!"
      />
    </Layout>
  </>
)
